import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {useConnect} from "../api/contracts";
import {Modal, Button, Radio, Select, Pagination, Form, Input, notification} from 'antd';
import MintImg from "../imgs/MintIMG.jpg"
import {getContractByContract, getContractByName} from "../api/connectContract";
import {dealMethod, viewMethod} from "../utils/contractUtil"
import {useNavigate} from "react-router-dom";

import BigNumber from "bignumber.js";
import judgeStatus from "../utils/judgeStatus";
import addressMap from "../api/addressMap";
import add from "../imgs/add.png";
import cut from "../imgs/remove.png";
import {USDTDecimals} from "../config/constants";
const usdtDecimal = 6
const MaxUint256 = BigNumber('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff').toFixed(0);

const CreatePage = (props) => {
    const [form] = Form.useForm();
    const history = useNavigate();
    const goPage = (url) => {
        history(url);
    }

    const CreatePage = styled.div`
      margin-top: 150px;
      color: #fff;

      .ant-form-item {
        .ant-form-item-row {
          display: flex !important;
          justify-content: space-between !important;
          padding-top: 10px;

          .ant-form-item-label {

            flex-grow: 2;
            text-align: left;

            label {
              font-size: 20px;
            }
          }

          .ant-select-selector {
            color: #fff !important;
            font-weight: bold;
            background: #333;
            border: none;
          }

          .ant-select-arrow {
            color: #fff !important;
          }

          .ant-input {
            color: #fff !important;
            font-weight: bold;
            background: #333;

            &::selection {
              color: #4A52FF;
            }
          }

          .ant-form-item-control {
          }

          .ant-form-item-control-input {
          }
        }
      }

      .create-box {
        width: 610px;
        background: #0E0D10;
        box-shadow: 0px 23px 15px 5px rgba(0, 0, 0, 0.6);
        border-radius: 20px 20px 20px 20px;
        border: 1px solid rgba(241, 210, 98, 0.3);
        margin: 0 auto;
        padding: 30px;

        .mint-img {
          width: 100%;

        }

        .price {
          display: flex;
          justify-content: space-between;
          padding-top: 10px;
          font-size: 20px;
        }

        .mint-btn {
          margin-top: 30px;
          border: none;
          width: 100%;
          height: 50px;
          background: #4A52FF;
          border-radius: 10px 10px 10px 10px;
          font-family: Roboto, Roboto;
          font-weight: bold;
          font-size: 18px;
          color: #FFFFFF;
        }

        .white-btn {
          margin-top: 30px;
          border: none;
          width: 100%;
          height: 50px;
          font-family: Roboto, Roboto;
          font-weight: bold;
          font-size: 18px;
          color: #FFFFFF;
          background: #272727;
          border-radius: 10px 10px 10px 10px;
          border: 1px solid rgba(255, 255, 255, 0.1);
        }
      }

      .my-nft {
        width: 80%;
        margin: 100px auto;
        background: rgb(14, 13, 16);
        box-shadow: 0px 23px 15px 5px rgba(0, 0, 0, 0.6);
        border-radius: 20px 20px 20px 20px;
        border: 1px solid rgba(241, 210, 98, 0.3);
        padding: 30px;

        .nft-list {
          display: grid;
          grid-template-columns: repeat(5, minmax(130px, 1fr));
          gap: 10px;
          margin: 50px 0;

          .nft-item {
            padding: 5%;
            border-radius: 5%;
            background: #0e0d10;

            img {
              width: 100%;
            }

            .id-box {
              display: flex;
              justify-content: space-between;
              padding: 5px 0;
              font-size: 18px;
            }
          }
        }

      }

      .myNftTitle {
        font-size: 26px;

      }

      @media screen and (max-width: 1000px) {
        .myNftTitle {
          font-size: 20px;
          padding-bottom: 20px;
        }

        .hasnotnft {
          font-size: 20px !important;
        }

        .my-nft {
          width: 90%;
          margin: 100px auto;

          .nft-list {
            display: grid;
            grid-template-columns: repeat(2, minmax(130px, 1fr));
            gap: 10px;
            margin: 50px 0;

            .nft-item {
              padding: 5%;
              border-radius: 5%;
              background: #0e0d10;

              img {
                width: 100%;
              }

              .id-box {
                display: flex;
                justify-content: space-between;
                padding: 5px 0;
                font-size: 18px;
              }
            }
          }

        }
      }
      @media screen and (max-width: 1000px) {
        .create-box {
          width: 90vw !important;


          .price {
            font-size: 16px;
          }

          .mint-btn {

          }

          .white-btn {

          }
        }
      }

    `
    let {state, dispatch} = useConnect();
    const [contract, setContract] = useState(null)
    const [whitelistArr, setWhiteListArr] = useState([])
    const [owner, setOwner] = useState("  ")
    const [allowance, setAllowance] = useState()
    const [isAdmin, setIsAdmin] = useState(false)
    const [isAdmin1, setIsAdmin1] = useState(false)
    const [isAdmin2, setIsAdmin2] = useState(false)
    const [usdtBalance, setUSDTBalance] = useState(0)

    const [price, setValue] = useState(0)

    const [pageCount, setPageCount] = useState(20)
    const [curPage, setCurPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [isShowWhite, setIsShowWhite] = useState(false)
    const [canMintAMount, setCanMintAMount] = useState(0)
    const [mintedMount, setCanMintedAMount] = useState(0)
    const [l2canMintedMount, setL2CanMintedAMount] = useState(0)
    const [l3canMintedMount, setL3CanMintedAMount] = useState(0)
    const [myNFT, setMyNFT] = useState([])

    const [selectValue, setSelectValue] = useState(1)

    const [optionsArr, setOptionsArr] = useState([])

    const [initAmount, setInitAmount] = useState(0)
    const [totalMint, setTotalMint] = useState(0)


    const [isW, setIsW] = useState(false)

    const openNotification = (message) => {
        notification.error({
            message: message,
            description:
                "",
            onClick: () => {
                console.log('Notification Clicked!');
            },
        });
    };
    const handleViewCoinMethod = async (name, params) => {
        let contractTemp = await getContractByName("usdt", state.api,)
        return viewMethod(contractTemp, state.account, name, params)
    }
    const handleDealCoinMethod = async (name, params) => {
        let contractTemp = await getContractByName("usdt", state.api,)
        return dealMethod(contractTemp, state.account, name, params)
    }
    const handleViewMethod = async (name, params) => {
        let contractTemp = await getContractByName("MintSOL", state.api,)
        if (!contractTemp) {
            openNotification("Please connect")
        }
        return await viewMethod(contractTemp, state.account, name, params)
    }
    const handleDealMethod2 = async (name, params) => {
        let contractTemp = await getContractByName("MintSOL", state.api,)
        if (!contractTemp) {
            openNotification("Please connect")
        }
        dealMethod(contractTemp, state.account, name, params)
    }
    const getUserMintIds = async () => {
        const res = await handleViewMethod("getUserMintIds", [state.account])
        setMyNFT(res)
    }
    const getUSDTBalance = async () => {
        const res = await handleViewCoinMethod("balanceOf", [state.account])
        setUSDTBalance(BigNumber(res).div(10**USDTDecimals).toFixed(0))
    }
    const getValue = async () => {
        const res = await handleViewMethod("value", [])
        setValue(res)
    }
    const getAllowance = async () => {
        const res = await handleViewCoinMethod("allowance", [state.account, addressMap["MintSOL"].address])
        setAllowance(res)
    }
    const getOwner = async () => {
        const Admin = await handleViewMethod("owner", [])
        setOwner(Admin)
    }

    const approve = async () => {
        await handleDealCoinMethod("approve", [addressMap["MintSOL"].address, MaxUint256])
        getAllowance()
    }
    const checkIsNotWhiteListUser = async () => {
        const res = await handleViewMethod("checkIsNotWhiteListUser", [state.account])
        setIsW(res)
    }
    const MintNFT = async () => {
        if (selectValue == 0) {
            openNotification("Please select amount")
        }
        if (BigNumber(selectValue).gt(canMintAMount - mintedMount)) {
            openNotification("Excess of the reissuable balance")
            return
        }

        await handleDealMethod2("mintFireOgNft", [selectValue])
        getAllowance()
        getWhiteListCanMintAmount()
        getAmount()
        getUSDTBalance()
        setTimeout(()=>{
            getAllowance()
            getWhiteListCanMintAmount()
            getAmount()
            getUSDTBalance()
        },1000)


    }
    const onChangePage = async (page) => {
        await setCurPage(page)
    }
    const handleShowSizeChange = async (page, count) => {
        setPageCount(count)
    }
    const getAmount = async () => {
        const amount = await handleViewMethod("initAmount", [])
        setInitAmount(amount)
        const res = await handleViewMethod("totalMint", [])
        setTotalMint(res)
    }
    const getisAdmin = async () => {
        const Admin1 = await handleViewMethod("adminLevel2", [state.account])
        const Admin2 = await handleViewMethod("adminLevel3", [state.account])
        setIsAdmin(Admin1 || Admin2)
        setIsAdmin2(Admin2)
        setIsAdmin1(Admin1)
    }
    const getWhiteListCanMintAmount = async () => {
        const res = await handleViewMethod("whiteListCanMintAmount", [])
        const res2 = await handleViewMethod("whiteListMintAmount", [state.account])
        // const l2 = await handleViewMethod("l2whiteListCanMintAmount", [])
        // const l3 = await handleViewMethod("l3whiteListCanMintAmount", [])

        setCanMintedAMount(res2)

        setCanMintAMount(res)
        let tempArr = []
        for (let i = 0; i < (res - res2); i++) {
            tempArr.push({
                label: i + 1,
                value: i + 1
            })
        }
        setOptionsArr(tempArr)
    }
    const getWiteList = async () => {
        let tempArr = [], whiteArr = []
        for (let i = 0; i < 7; i++) {
            whiteArr = await handleViewMethod("getWiteList", [])

            const inW1 = whiteArr.some(item => {
                return item.toLowerCase() == state.account.toLowerCase()
            })
            tempArr.push(inW1)

        }
        setWhiteListArr(whiteArr)
        setTotal(whiteArr.length)

    }

    const handleChange = (val) => {
        console.log(val)
        setSelectValue(val)

    }
    useEffect(async () => {

        let judgeRes = await judgeStatus(state)
        if (!judgeRes) {
            return
        }
        setMyNFT([])
        getOwner()
        getAllowance()
        getisAdmin()
        getWiteList()
        getUserMintIds()
        checkIsNotWhiteListUser()
        getWhiteListCanMintAmount()
        getisAdmin()
        getAmount()
        getUSDTBalance()

        getValue()
    }, [state.account])
    useEffect(async () => {

        let judgeRes = await judgeStatus(state)
        if (!judgeRes) {
            return
        }
        getOwner()
        getAllowance()
        getisAdmin()
        getWiteList()

        checkIsNotWhiteListUser()
        getWhiteListCanMintAmount()
        getisAdmin()
        getAmount()

    }, [isAdmin1, isAdmin2, isAdmin1])

    return (
        <CreatePage>
            <Modal className="nft-dialog" title="Whitelist" open={isShowWhite} footer={null} onCancel={() => {
                setIsShowWhite(false)
            }}>
                <div className='superdao-list-box up-list-box'>
                    <div className='list-header up-header'>
                        <div className='col'>
                            No.
                        </div>
                        <div className='col'>
                            Address
                        </div>
                    </div>
                    {whitelistArr.map((item, index) => {
                        return (index >= pageCount * (curPage - 1) && index < pageCount * curPage &&
                            <div className='list-item up-item' key={index}>
                                <div className='col no'>
                                    {index + 1}
                                </div>
                                <div className='col address'>
                                    <a style={{whiteSpace:"nowrap"}}>
                                        {item}
                                    </a>
                                </div>


                            </div>)
                    })}

                </div>
                <div className="pagination">

                    <Pagination current={curPage} showSizeChanger
                                onShowSizeChange={handleShowSizeChange}
                                onChange={onChangePage} total={total}
                                defaultPageSize={pageCount}/>

                </div>
            </Modal>
            <div className="create-box">
                <img className="mint-img" src={MintImg} alt=""/>
                <div className="price">
                    <span>
                        Total amount
                    </span>
                    <span>
                        {initAmount}
                    </span>
                </div>
                <div className="price">
                    <span>
                        Minted amount
                    </span>
                    <span>
                        {totalMint}
                    </span>
                </div>
                <div className="price">
                    <span>
                        Remained
                    </span>
                    <span>
                        {BigNumber(initAmount).minus(totalMint)>0?BigNumber(initAmount).minus(totalMint).toFixed(0):0}
                    </span>
                </div>
                <div className="price">
                    <span>
                        You can mint amount
                    </span>
                    <span>
                        {(canMintAMount - mintedMount) > 0 ? (canMintAMount - mintedMount) : 0}
                    </span>
                </div>


                <div className="price">
                    <span>
                        Price
                    </span>
                    <span>
                        {BigNumber(price).dividedBy(10**USDTDecimals).toFixed(0)} USDT
                    </span>
                </div>
                {isW ? <>
                        <Form form={form} name="control-hooks" className="form">
                            <Form.Item
                                name="amount"
                                validateTrigger="onBlur"
                                label={"Amount "}
                                validateFirst={true}
                            >


                                <div className="input-box">
                                    <Select
                                        className="select-chain"
                                        defaultValue="Goerli network"
                                        onChange={handleChange}
                                        value={selectValue}
                                        options={optionsArr}
                                    />
                                </div>
                            </Form.Item>
                        </Form>
                        {BigNumber(allowance).lt(selectValue * 10 ** 18)  ? <Button className="mint-btn" onClick={() => {
                            approve()
                        }}>Approve</Button> : <Button className="mint-btn" onClick={() => {
                            MintNFT()
                        }}>Mint</Button>
                        }
                    </>
                    : <div className="price">
                        Not in whitelist
                    </div>}

                <div className="price">
                    <span>
                        Balance
                    </span>
                    <span>
                        {usdtBalance} USDT
                    </span>
                </div>
                <Button className="white-btn" onClick={() => {
                    setIsShowWhite(true)
                }}>Whitelist</Button>

                {owner.toLowerCase() == state.account.toLowerCase() &&
                    <Button className="mint-btn" onClick={() => {
                        goPage("/NFTAdmin")
                    }}>Level1 Admin</Button>}
                {isAdmin &&
                    <Button className="mint-btn" onClick={() => {
                        goPage("/Admin")
                    }}>Admin</Button>}
            </div>
            <div className={"myNFT"}>

                <div className="my-nft ">
                    <div className="myNftTitle">My NFT</div>
                    {myNFT.length==0&&<div className="hasnotnft" style={{textAlign:"center",fontSize:"26px"}}>
                        There is no NFT yet
                    </div>}
                    <div className="nft-list">
                        {myNFT.map((item, index) => {
                            return (<div className="nft-item" key={index}>
                                <img src={MintImg}/>
                                <div className="id-box">
                                    <div className="left">ID</div>
                                    <div className="right">
                                        {item}
                                    </div>
                                </div>
                            </div>)
                        })}

                    </div>
                </div>
            </div>

        </CreatePage>
    )
}
export default CreatePage
