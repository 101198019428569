import erc20Abi from "../abi/erc20.json";
import MintSOL from "../abi/fireOgv2.json";

let CONTRACTS
CONTRACTS = {
    usdt: {address: "0x55d398326f99059fF775485246999027B3197955", abi: erc20Abi},
    MintSOL: {address: "0x594eb8913D06Af9229Da7989d353ca2EB17782f0", abi: MintSOL},

};
// bnb test
// CONTRACTS = {
//     usdt: {address: "0xC89C114d3A66cbbBbe7B1F630FD1A36bf7Fd992f", abi: erc20Abi},
//     MintSOL: {address: "0xD64E08b6384D8bB534F63644E6827d7c8a24882D", abi: MintSOL},
//
// };
export default CONTRACTS
